.back-link{
    background-color: #000;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: inherit;
    text-decoration: inherit;
    position: relative;
    z-index: 3;
    margin: 1rem 0 0 1rem;
}

.back-link > svg {
    width: 1rem;
    height: 1rem;
    margin-left: -0.125rem;
    display: block;
    vertical-align: middle;
    color: white;
}
.person-detail-container {
    position: relative;
}

.select-person-container{
    height: calc(100vh - 1rem);
    overflow: auto;
    border-radius: 0.5rem;
    background-color: #18181b;
}

.videos-container {
    background-color: #18181bf0;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    z-index: 1;
    padding: 1rem;
}

.video-card {
    padding: 1rem;
    width: 18rem;
    background-color: #71717a0d;
    border-radius: 0.5rem;
}

.video-card > div>img{
    margin-bottom: 1rem;
}

.video-card:hover {
    background-color: #71717a33;
}

.video-card-description {
    font-size: 0.7rem;
}

.peron-detail-info {
    background: #00000070;
    display: flex;
    flex-direction: row;
    padding: 1.5rem;
    gap: 2rem;
    position: relative;
    z-index: 1;
}

.person-detail-image {
    width: 15rem;
    height: 15rem;
    flex: none;
}

.person-detail-image>img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    max-width: 100%;
    --tw-shadow: 5px 0 30px 0px rgba(0, 0, 0, .3);
    --tw-shadow-colored: 5px 0 30px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.person-detail-background-div {
    display: block;
    position: absolute;
    inset: 0;
    border-radius: 0.5rem;
    --tw-gradient-from: var(--context-color) var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.person-detail-background-div>div {
    position: absolute;
    inset: 0;
    --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), transparent var(--tw-gradient-via-position), var(--tw-gradient-to);
    --tw-gradient-from: #18181b var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(24 24 27 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.person-detail-background-div>img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    inset: 0;
    position: absolute;
    max-width: 100%;
    display: block;
    opacity: .2;
    mix-blend-mode: overlay;
    --tw-blur: blur(12px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.person-header {
    display: flex;
    justify-content: space-between;
}

.person-header-title-badge {
    display: flex;
    align-items: center;
    margin-top: -1rem;
}

.person-header-title-badge>p {
    font-size: 2.5rem;
}

.person-header-title-badge>div {
    margin-left: 1rem;
}

.noData {
    color: white;
    text-align: center;
    padding: 1rem;
}

@media (max-width: 1024px) {
    .person-header-title-badge>p {
        font-size: 2rem;
    }
    .person-header-title-badge {
        display: block;
    }
    
}

@media (max-width: 640px) {
    .peron-detail-info{
        display: block;
    }
    .person-header-title-badge {
        display: flex;
    }
    .person-detail-image{
        margin-left: auto;
        margin-right: auto;
    }
    .person-header {
        padding-top: 1rem;
    }
    .person-header-title-badge{
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .person-header-title-badge > p {
        font-size: 1.8rem;
    }
    .person-header-title-badge>div {
        margin-left: 0rem;
        justify-content: center;
    }
}