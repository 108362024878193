.container {
    padding: 0 1rem;
}

.masterCardImgDiv {
    flex-grow: 0.1;
}

.masterCardImg {
    max-width: 120px;
}

@media (max-width: 1024px) {
    .masterCardImgDiv {
        flex-grow: 0.18;
    }
}

@media (max-width: 915px) {
    .masterCardImgDiv {
        flex-grow: 0.2;
    }
}
@media (min-width: 768px) {
    .container {
        margin: 0;
    }
}

@media (max-width: 640px) {
    .masterCardImgDiv {
        flex-grow: 0.3;
    }

    .masterCardImg {
        max-width: 80px;
    }
}

@media (max-width: 400px) {
    .masterCardImgDiv {
        flex-grow: 0.5;
    }

    .navBarDiv {
        justify-content: start !important;
    }
}