.country {
  padding: 0 0.5rem;
  margin: 0 0.5rem;
}
.nav {
    font-size: 10px;
    font-family: 'Demonized';
}

.activeCountry {
    border-bottom: 1px solid white !important;
}

.country.nav-tabs .nav-link:focus,
.country.nav-tabs .nav-link:hover {
  border: 1px solid transparent;
}

.font_size {
  font-size: 15px;
}

@media (max-width: 640px) {
  .font_size {
    font-size: 9px;
  }
}