.control-panel {
    font-size: 16px;
    color: black;
    background-color: white;
    border-radius: 5px;
    width: fit-content;
  }
  
  .clickable {
    cursor: pointer;
  }
  
  .disabled {
    cursor: not-allowed;
    color: lightgray;
  }