.person-card {
    display: flex;
    outline: 2px solid transparent;
    background: #18181b;
    outline-offset: 2px;
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / .1), 0 4px 6px -4px rgb(0 0 0 / .1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 0.375rem;
}

.person-card:hover {
    background-color: #71717a1a;
    --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / .1), 0 8px 10px -6px rgb(0 0 0 / .1);
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.person-img-div {
    width: 12rem;
    height: 12rem;
    flex: none;
}

.person-img-div>a>img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-width: 100%;
    border-radius: 0.5rem;
}

.person-detail {
    padding-left: 1rem;
}

.person-detail-title-badge {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.person-detail-title-badge>p {
    font-size: 2.5rem;
}

.text-read-more {
    color: white;
    text-decoration: underline !important;
    text-decoration-color: white !important;
}

@media (max-width: 1024px) {
    .person-detail-title-badge>p {
        font-size: 1.8rem;
    }

    .person-detail-title-badge>div {
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .person-detail-title-badge {
        padding-top: 1rem;
        display: block;
    }
}

@media (max-width: 640px) {
    .person-detail-title-badge {
        display: block;
    }

    .person-description {
        text-align: start
    }

    .person-card {
        display: block;
        text-align: -webkit-center;
    }

    .person-img-div {
        width: 14rem;
        height: 14rem;
        flex: none;
    }
}