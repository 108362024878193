.disabled_swiper_button {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}

.person-coin {
  padding: 0.4rem;
  border-radius: 50%;
  transition-duration: 0.5s;
}

.person-coin:hover {
  padding: 0.5rem;
  background-color: #383838;
  border-radius: 50%;
}