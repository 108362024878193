.selected-coin{
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
}
.selected-coin> p{
    font-size: 3rem;
    padding-left: 1rem;
}

@media (max-width: 640px) {
    .selected-coin> p{
        font-size: 2rem;
        padding-left: 0.5rem;
    }
}