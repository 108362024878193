@charset "UTF-8";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");

#connectButton {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  font-family: "Poppins", sans-serif;
  box-shadow: 0px 1px 3px hsla(0, 0%, 0%, 0.15);
  background-image: linear-gradient(#38cb7c, #1cb462);
  border-radius: 8px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 11px;
  text-decoration: none;
  transition: 0.3s;
  max-width: 340px;
  vertical-align: middle;
  letter-spacing: 0.5px;
}

#connectButton:before {
  background: url(/public/assets/handcash_white_icon.png)
    no-repeat scroll center center / 100% auto rgba(0, 0, 0, 0);
  content: "";
  display: inline-block;
  color: #fff;
  height: 20px;
  margin-right: 12px;
  margin-bottom: 1px;
  position: relative;
  vertical-align: middle;
  width: 20px;
}

#connectButton:hover {
  background-image: linear-gradient(#31c475, #16b15d);
  top: 1px;
  box-shadow: 0px 3px 6px hsla(0, 0%, 0%, 0.15);
}

#connectButton:active {
  background-image: linear-gradient(#38cb7c, #1cb462);
  position: relative;
  top: 1px;
  box-shadow: 0px 0px 0px hsla(0, 0%, 0%, 0.15);
}
