* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none !important;
}

body {
  height: 100vh;
  background-color: black;
}

/* @font-face {
  font-family: 'Demonized';
  font-style: normal;
  font-weight: 400;
  src: local('Demonized'), local('Demonized-Regular'),
    url(https://allfont.net/cache/fonts/demonized_a2afd157ba7955ca326492b631c8b2d1.woff) format('woff'),
    url(https://allfont.net/cache/fonts/demonized_a2afd157ba7955ca326492b631c8b2d1.ttf) format('truetype');
} */

.font_family {
  font-family: 'Demonized';
}

.active {
  color: #ecda0d !important;
}


ul li {
  margin: 0 0 34px 0;
  font-size: 11px;
}

.description-color {
  color: #9ca3ae;
}

.logo {
  /*width: 100%;
  max-width: 120px;*/
  max-width: 220px;
  width: 100%;
  max-height: 100px;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 0.5rem;
}

.swiper {
  margin-top: 20px;
  padding-bottom: 25px !important;
}

.swiper-pagination-bullet {
  background: #808080 !important;
}

.swiper-pagination-bullet-active {
  background: #fff !important;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-wrapper {
  align-items: center;
}

.swiper-button-next,
.swiper-button-prev {
  top: 37% !important;
  color: white !important;
}

.swiper-button-next:after,
.swiper-button-prev:after,
.swiper-button-next:before,
.swiper-button-prev:before {
  font-size: 20px !important;
  font-weight: bold !important;
}

.swiper-slide img {
  display: block;
  width: 140px;
  object-fit: cover;
}

.personPage {
  column-gap: 25px;
}

.flex_gap {
  row-gap: 25px;
  column-gap: 25px;
}


@media (max-width: 1024px) {
  .movie {
    width: calc(50% - 10px);
  }
}

@media (max-width: 915px) {

  .with {
    width: 50%;
  }

  .movie {
    width: 100%;
  }
}

@media (max-width: 640px) {


  ul li {
    font-size: 9px;
  }


  .with {
    width: 50%;
  }


  /* .logo {
    max-width: 80px;
  } */


  .home-videos .swiper-button-prev,
  .home-videos .swiper-button-next,
  .home-coins .swiper-button-prev,
  .home-coins .swiper-button-next {
    display: none;
  }

  .personPage {
    flex-wrap: wrap;
    justify-content: center;
  }

  .personPage .title {
    text-align: center;
    margin-top: .5rem;
  }
}

.home-videos .swiper .swiper-wrapper .swiper-slide {
  background-color: #202020;
  border-radius: 8px;
}


.home-videos .swiper .swiper-wrapper .swiper-slide p {
  color: white;
  font-weight: bold;
  font-size: 16px;
  /* max-width: 80%; */
  text-align: start;
  /* margin: auto; */
  /* margin-bottom: 15px; */
  text-transform: capitalize;
}

.home-videos .swiper .swiper-wrapper .swiper-slide:hover {
  background-color: #383838;
}

*::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #323232;
}

*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: transparent;
}

@font-face {
  font-family: Demonized;
  src: url('./fonts/Demonized.ttf');
}