.select-video-container{
    height: calc(100vh - 1rem);
    overflow: auto;
    border-radius: 0.5rem;
    background-color: #18181b;
}
.video-player-container {
    background-color: #18181bf0;
    padding: 1rem;
    border-radius: 0.5rem;
    text-align: center;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    /* --tw-gradient-from: #18181b var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(24 24 27 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);

    background-image: linear-gradient(to top, var(--tw-gradient-stops)); */
}

.video-player-container>video {
    margin-left: auto;
    margin-right: auto;
}

.video-title {
    color: white;
    font-size: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.video-description {
    color: #9ca3ae;
    font-size: 1rem;
    padding: 1rem;
    text-align: start;
}

@media (max-width: 640px) {
    .video-title {
        color: white;
        font-size: 1.5rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}