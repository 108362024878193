.noData {
  color: white;
  text-align: center;
}

.category-video-container {
  background-color: #18181bf0;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem;
  border-radius: 0.5rem;

  --tw-gradient-from: #18181b var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(24 24 27 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);

  background-image: linear-gradient(to top, var(--tw-gradient-stops));
  transition-duration: 0.5s;
}

.category-video-card {
  padding: 1rem;
  width: 18rem;
  background-color: #71717a0d;
  border-radius: 0.5rem;
}

.category-video-card:hover {
  background-color: #71717a33;
}