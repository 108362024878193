.person-container {
  background-color: #18181b;
  height: calc(100vh - 1rem);
  overflow: auto;
  border-radius: 0.5rem;
}

.noData {
  color: white;
  text-align: center;
}

.person-list-container {

  border-radius: 0.5rem;
  padding: 1rem;
}


@media (max-width: 640px) {

  .person-list-container {
    /* width: 27rem; */
    margin-left: auto;
    margin-right: auto;

  }

  .person-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}